import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Data from './contactData.json';
import { Box, Paper, Typography } from '@mui/material'
import { ClockIcon } from '@heroicons/react/24/outline';

const OpeningTimes = (props) => {
  const openingHours = Data.openingHours;
  const color = props.bg
  return (
    <Paper elevation={5} style={{ backgroundColor: color }} sx={{ padding: "20px" }} backgroundColor={color}>
      <Box>
        <Typography variant='h5'>
          <Box display={"flex"} alignItems={"center"} gap={1}>
            Öffnungszeiten
          </Box>
        </Typography>
      </Box>
      <TableContainer sx={{ width: "250px", padding: "10px" }} elevation={5}>
        <Table sx={{ width: "250px" }}>
          <TableBody>
            {openingHours.map((row) => (
              <TableRow
                key={row.day}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 }, [`& .${tableCellClasses.root}`]: {
                    borderBottom: "none",
                    padding: '5px 10px'
                  }
                }}
              >
                <TableCell align="right">{row.day}</TableCell>
                <TableCell align="left">{row.hours}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {Data.additionalInformation}
    </Paper>

  )
}

export default OpeningTimes
