import * as React from 'react'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import TabComponentGeraete from './tabComponentGeraete'
import TabComponentLeistungen from './tabComponentLeistungen'
import TabComponentRobbi from './tabComponentRobbi'
import colors from '../../colors'
import ImageSlideshow from './imageSlideshow'
const Offers = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box  display={"flex"} justifyContent={"center"} alignItems={"center"} width={"100%"}>
            <Box minHeight={"80vh"}  maxWidth={"100vw"} width="48em">
                <Box p={2}>
                    <Typography variant='h5'>Wir decken das klinische, diagnostische und therapeutische Spektrum der Allgemein- und Inneren Medizin ab. </Typography>
                </Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange}
                        sx={{
                            "& .MuiTab-root.Mui-selected": {
                                color: colors.primary
                            }
                        }}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: colors.primary,
                            }
                        }
                        }
                    >
                        <Tab label="Leistungen" />
                        <Tab label="Geräte" />
                        <Tab label={
                            <Box display={"flex"} flexDirection={"row"} alignItems={"center"} alignContent={"center"} justifyContent={"center"} gap={1}>
                                <Box>
                                    Robbi
                                </Box>
                                <Box
                                    component="img"
                                    sx={{
                                        height: "1.5em",
                                        width: "auto",
                                    }}
                                    alt={"Robbi"}
                                    src="./WALL-E.png"
                                />

                            </Box>
                        }
                        />
                        <Tab label="Bilder" />
                    </Tabs>
                </Box >
                <Box ml={2} mr={2}>
                    {value === 0 && <TabComponentLeistungen />}
                    {value === 1 && <TabComponentGeraete />}
                    {value === 2 && <TabComponentRobbi />}
                    {value === 3 && <ImageSlideshow />}
                </Box>
            </Box>

        </Box>
    );
}

export default Offers
