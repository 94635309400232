import { Box, Paper, Typography, Link } from '@mui/material'
import React from 'react'
import Data from './contactData.json';
import { MapPinIcon, PhoneIcon, AtSymbolIcon } from '@heroicons/react/24/outline';

const address = Data.address;
const Address = () => {
  return (
    <Paper elevation={5} sx={{ padding: "20px" }}>
      <Box>
        <Typography variant='h5'>
          <Box display={"flex"} alignItems={"center"} marginBottom={2} gap={1}>
            <MapPinIcon width={"1em"} />
            <Box>{address.Anschrift}</Box>
          </Box>
        </Typography>
      </Box>
      <Box>{address.Strasse}</Box>
      <Box>{address.Ort}</Box>
      <Box display={"flex"} alignItems={"center"} marginTop={1} gap={1}>
        <PhoneIcon width={"1em"} />
        <Link href={`tel:${address.Telefon}`}>{address.Telefon}</Link>
      </Box>
      <Box display={"flex"} alignItems={"center"} marginTop={1} gap={1}>
        <AtSymbolIcon width={"1em"} />
        <Link href={`mailto:${address.Email}`}>{address.Email}</Link>
      </Box>
    </Paper>
  )
}

export default Address

