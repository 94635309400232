import logo from './logo.svg';
import './App.css';
import { Route, Routes } from "react-router-dom";
import { StrictMode } from "react";
import { Box } from "@mui/material"; // Removed unnecessary imports
import Navbar from './sharedComponents/navbar';
import Home from './sites/home/home';
import Contact from './sites/contact/contact';
import Jobs from './sites/jobs/jobs';
import Team from './sites/team/team';
import Offers from './sites/offers/offers';
import Emergency from './sites/emergency/emergency';
import Footer from './sharedComponents/footer';

function App() {
  const links = [
    { path: "/", displayName: "Home", component: Home },
    { path: "/angebot", displayName: "Angebot", component: Offers },
    { path: "/team", displayName: "Team", component: Team },
    { path: "/notfall", displayName: "Notfall", component: Emergency },
    { path: "/jobs", displayName: "Jobs", component: Jobs },
    { path: "/kontakt", displayName: "Kontakt", component: Contact },
  ];

  return (
    <StrictMode>
      <Box
        display={"flex"}
        flexDirection={"column"}
      >
        <Navbar links={links} />
        {/* Define part which is rendered according to the URL */}
        <Routes>
          {links.map((link) => (
            <Route key={link.path} path={link.path} element={<link.component />} />
          ))}
        </Routes>
        <Footer />
      </Box>
    </StrictMode>
  );
}

export default App;
