import React from 'react'
import {Box, Link} from '@mui/material'
import Data from './possibleOffers.json'
const TabComponentRobbi = () => {
    return (
        <Box
            role="tabpanel"
            display={"flex"}
            flexDirection={"column"}
            paddingBottom={"30px"}
        >
            <Box>
                {Data.robbi.Text}
                <br/>
                <Link href="https://www.youtube.com/watch?v=i4A-HbjQp70" target="_blank">https://www.youtube.com/watch?v=i4A-HbjQp70</Link>
            </Box>

            <Box
                component="img"
                sx={{
                    height: "auto",
                    width: "100%",
                }}
                paddingTop={"10px"}
                alt={"Robbi"}
                src={Data.robbi.BildURL}
            />
        </Box>
    )
}

export default TabComponentRobbi
