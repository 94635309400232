import React from 'react';
import OpeningTimes from '../../sharedComponents/openingTimes';
import { Box, Typography } from '@mui/material';
import Address from "../../sharedComponents/address"
import GeneralInformation from '../../sharedComponents/generalInformation';
//import CustomMap from './CutomMap';
const Contact = () => {
    return (
        <Box paddingBottom={"30px"} >
            <Box paddingTop={"20px"} 
            sx={{
                paddingLeft:{
                    xs: 0,
                    sm: "300px"
                },
                alignItems:{
                    xs:"center",
                    sm:"left"
                },
                justifyContent:{
                    xs:"center"
                },
                display:{
                    xs:"flex",
                    sm:"block"
                }
            }}>
                <Typography variant='h3' sx={{
                    fontSize:{
                        xs: "1.7rem",
                        sm: "2.1rem"
                    }
                }}>
                    Kontakt und Standort
                </Typography>
            </Box>
            <Box gap={5} marginTop={5} sx={
                                        {
                                            alignItems:{ 
                                                xs: "center",
                                                sm: "left"
                                            },
                                            display:{
                                                xs:"block",
                                                sm:"flex"
                                            }
                                        }
            }>
                <Box display={"flex"} flexDirection={'column'} gap={5} sx={
                        {
                            paddingLeft:{
                                xs:0,
                                sm:"300px"
                            },
                            width: {
                                xs: "100vw",
                                sm:"300px"
                            },
                            alignItems:{ 
                                xs: "center",
                                sm: "left"
                            }
                        }
                }>
                    <Box minWidth={"300px"}><Address /></Box>
                    <Box maxWidth={"300px"}><OpeningTimes /></Box>
                    <Box maxWidth={"300px"}><GeneralInformation /></Box>
                </Box>
                <Box
                    component="img"
                    sx={{
                        width: {
                            xs:"100%",
                            sm: 700
                        },
                        marginLeft: {
                            xs: 0,
                            sm:"300px"
                        },
                        marginTop:{
                            xs: 5
                        }
                    }}
                    alt="Die Praxis"
                    src="Karte.jpg"
                />
            </Box>

        </Box>
    );
}

export default Contact;
