import React from 'react'
import Data from './possibleOffers.json'
import {List, ListItem, Box, Tooltip } from '@mui/material'
import { PhotoIcon } from '@heroicons/react/24/outline';
const TabComponentGeraete = () => {
    return (
            <Box
                role="tabpanel"
                display={"flex"}
                flexDirection={"column"}
            >
                {
                    <List
                        sx={{
                            p: 3,
                            listStyleType: 'disc',
                            pl: 2,
                        }}
                    >
                        {
                            Data.geraete.map(element => (
                                <Tooltip title={
                                    <div style={{ textAlign: 'center' }}>
                                      <img
                                        src={element.Image}
                                        alt="Tooltip Image"
                                        style={{ width: 'auto', height: '40vh' }}
                                      />
                                    </div>
                                  }
                                  componentsProps={{
                                    tooltip: {
                                      sx: {
                                        bgcolor: 'transparent'
                                      },
                                    },
                                  }}
                                followCursor>
                                    <ListItem padding={2} sx={{ display: 'list-item' }}>
                                        {element.Text}
                                        <PhotoIcon width={"1em"}/>
                                    </ListItem>
                                </Tooltip>

                            ))
                        }
                    </List>

                }
            </Box>
    )
}

export default TabComponentGeraete
