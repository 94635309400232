import { Box, Paper, Typography } from '@mui/material'
import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

const TeamMember = ({ data, index }) => {
    const position = index % 2 == 1 ? "row" : "row-reverse"
    return (
        <Paper elevation={5}>
            <Box
                display={"flex"}
                gap={5}
                p={2}
                m={2}
                sx={{
                    flexDirection: {
                        xs: "row",
                        sm: position
                    }
                }}
            >
                <Box>
                    <Box
                        component="img"
                        sx={{
                            height: {
                                xs: "155px",
                                sm: "300px"
                            },
                        }}
                        alt={data.Name}
                        src={data.BildURL}
                    />
                </Box>
                <Box display={"flex"} flexDirection={"column"} >
                    <Box>
                        <Typography variant='h5'>
                            {data.Name}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant='h6'>
                            {data.Titel}
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: {
                            xs: 'none', // Hide on extra-small screens (mobile)
                            sm: 'block', // Hide on small screens (tablet)
                        }
                    }} >
                        {data.Mehr}
                    </Box>
                    {data.Mehr !== undefined && (
                        <Accordion sx={{
                            display: {
                                xs: 'block', // Show on extra-small screens (mobile)
                                sm: 'none',  // Hide on small screens (tablet)
                            }
                        }}>
                            <AccordionSummary
                                expandIcon={<ChevronDownIcon width={"1em"} />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                mehr zu dieser Person
                            </AccordionSummary>
                            <AccordionDetails>
                                {data.Mehr}
                            </AccordionDetails>
                        </Accordion>
                    )}
                </Box>
            </Box>
        </Paper >
    )
}

export default TeamMember
