import { Box } from '@mui/material';
import React, { useState } from 'react';

const ImageSlideshow = () => {
  const images = [
    "./rooms/Empfang.JPG",
    "./rooms/SZ1.JPG",
    "./rooms/SZ2.JPG",
    "./rooms/SZ3.JPG",
    "./rooms/SZ4.JPG",
    "./rooms/Wartezimmer.JPG",
  ]

  const [imgIndex, setImageIndex] = useState(0)

  return (

    <Box width={"100%"} display={"flex"} flexDirection={"column"}>
      <Box display={"flex"} m={"auto"} gap={1} p={2}>
        <button class="prev" onClick={() => setImageIndex(imgIndex > 0 ? (imgIndex - 1) : (images.length - 1))}>❮</button>
        <button class="next" onClick={() => setImageIndex((imgIndex + 1) % images.length)}>❯</button>
      </Box>
      <Box>
        {images.map((slide, index) => {
          return (
            <Box display={index == imgIndex ? "Block" : "none"} justifyContent={"center"} width={"100%"} >
              <img src={slide} alt="Praxisbilder" class="slide" width={"100%"}/>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default ImageSlideshow
/*
        <div className={styles.container}>
        <Slide easing="ease">
          {images.map((slide, index) => {
            return (
              <div className={styles.slide} key={slide}>
                <div style={{ backgroundImage: `url(${images[index]})` }}>
                  <span>Slide {index + 1}</span>
                </div>
              </div>
            );
          })}
        </Slide>
      </div>*/