import React from 'react'
import { Box, Typography, Link } from '@mui/material'
import { PhoneIcon } from '@heroicons/react/24/outline'

const Contact = ({ title, number, text }) => {
    return (
        <Box width={400}>
            <Box key={title} elevation={5}>
                <Box p={2}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography variant="h5">{title}</Typography>
                        <Typography variant="h6">


                            <Link href={"tel:" + number}>
                                <Box display={"flex"} alignItems={"center"} gap={1}>
                                    <PhoneIcon width={"1em"} />
                                    {number}
                                </Box>
                            </Link>

                        </Typography>
                    </Box>
                    <Box>
                        <Typography>{text}</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Contact