import React from 'react'
import Data from './possibleOffers.json'
import {List, ListItem, Box } from '@mui/material'
const TabComponentLeistungen = () => {
    return (
            <Box
                role="tabpanel"
                display={"flex"}
                flexDirection={"column"}
            >
                {
                    <List
                        sx={{
                            p: 3,
                            listStyleType: 'disc',
                            pl: 2,
                        }}
                    >
                        {
                            Data.leistungen.map(element => (
                                <ListItem padding={2} sx={{ display: 'list-item' }}>
                                    {element.Text}
                                </ListItem>
                            ))
                        }
                    </List>

                }
            </Box>
    )
}

export default TabComponentLeistungen
