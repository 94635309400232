import { Box, Typography } from '@mui/material'
import React from 'react'
import { CalendarDaysIcon } from '@heroicons/react/24/outline'

const Job = ({ job }) => {
    return (
        <Box maxWidth={"42em"} marginBottom={5}>
            <Box>
                <Typography variant='h5' marginBottom={-1}>
                    {job.title}
                </Typography>
            </Box>
            <Box display={"flex"} alignItems={"center"} marginBottom={2} gap={1}>
                <CalendarDaysIcon height={"1em"} />
                <Box>
                    {job.datum}
                </Box>
            </Box>
            <Box>
                <Typography variant='body1'>

                    {job.text.split('\n').map((paragraph, index) => (
                        <React.Fragment key={index}>
                            {paragraph}
                            <br />
                        </React.Fragment>
                    ))}
                </Typography>
            </Box>
        </Box>
    )
}

export default Job
