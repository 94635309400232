import React from 'react'
import Data from './contactData.json';
import { Box, Paper, Typography } from '@mui/material';
const information = Data.information;
const GeneralInformation = () => {
  return (
    <Paper elevation={5} sx={{padding:"20px"}} >
        <Box>
        <Typography variant='h5'>
            Zugang zur Praxis
            </Typography>
        </Box>
        <Box >
            {information.split('\n').map((paragraph, index) => (
              <React.Fragment key={index}>
                  {paragraph}
                  <br />
              </React.Fragment>
            ))}
        </Box>
    </Paper>
  )
}

export default GeneralInformation
