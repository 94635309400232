import { Box, Paper, Typography, Link} from '@mui/material'
import React from 'react'
import Data from "./openJobs.json"
import Job from "./job"
import Address from '../../sharedComponents/address'

const Jobs = () => {
  return (
    <Box display={"flex"} justifyContent={"center"}  width={"100%"} marginTop={5} minHeight={"80vh"}>
      <Box display={"flex"} gap={10} flexWrap={"wrap"} marginLeft={4}>
        <Box maxWidth={"30em"}>
          <Box marginBottom={5}>
            <Typography variant='h4'>
              Lehrstellen
            </Typography>
            <Typography>
              Wir freuen uns über Bewerbungen für die Lehrstelle 2026 als Medizinische Praxisassistentin, bitte bis zum 31.10.2025 einreichen
            </Typography>
          </Box>
          <Box>
            <Typography variant='h4'>
              Kontakt
            </Typography>
              <Box display={"flex"} justifyContent={"left"} alignItems={"center"} width={"100%"} marginBottom={"10px"}>
                <Typography variant='h5'>
                Bitte senden Sie Ihre Bewerbung an:
                </Typography>
              </Box>
              <Box width={"266.75px"}>
              <Address/>
              </Box>
          </Box>
        </Box>
        <Box maxWidth={"30em"}>
          <Typography variant='h4'>
            Offene Stellen
          </Typography>
          {Data.map((job) => (
            <Job job={job} />
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default Jobs
