import React from 'react'
import { Link, Typography, Box, Menu, Button } from '@mui/material';
import colors from '../colors';
import { Bars3Icon } from '@heroicons/react/24/outline';



const Navbar = (props) => {
    const links = props.links;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box
            gap={3}
            alignItems={"center"}
            backgroundColor={colors.primary}
            flexWrap={"wrap"}
            width={"100%"}
        >
            <Box
                maxWidth={"1100px"}
                display={"flex"}
                flexWrap={"wrap"}
                mx={"auto"}
                my={"20px"}
                sx={{
                    width: {
                        xs: "100%",
                        sm: "60%"
                    }
                }}
            >

                <Box
                    sx={{
                        display: {
                            xs: 'block', // Show on extra-small screens (mobile)
                            sm: 'none',  // Hide on small screens (tablet)
                        },
                    }}
                >
                    <Button
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        sx={{ padding: 0, marginLeft: 1, marginRight: 1}}
                    >
                        <Bars3Icon color={"white"} width={"2.3rem"} />
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        sx={
                            {
                                mt: "1px", "& .MuiMenu-paper":
                                    { backgroundColor: "white", },
                            }}
                    >
                        {links.map((link) => (
                            <Link href={link.path} underline="none" key={link.path}>
                                <Typography variant="h5" p={1} width={"90vw"} color={"brown"}>
                                    {link.displayName}
                                </Typography>
                            </Link>
                        )
                        )}
                    </Menu>
                </Box>
                <Box marginRight={"auto"}>
                    <Link href={"/"} underline="none">
                        <Typography variant="h1" color={"white"}
                            sx={{
                                flexGrow: 1,
                                fontSize: {
                                    xs: "2rem",
                                    sm: "2.8rem"
                                },
                                transition: 'font-size 0.3s ease',
                            }}
                        >
                            Praxis Seilermatte
                        </Typography>
                    </Link>
                    <Box
                        sx={{
                            display: {
                                xs: 'none', // Hide on extra-small screens (mobile)
                                sm: 'block', // Hide on small screens (tablet)
                            }
                        }}>
                        Ein starkes Team
                    </Box>
                </Box>
                <Box display={"flex"} flexDirection={"row"} gap={2} mt={2.1} ml={2} sx={{
                    display: {
                        xs: 'none', // Hide on extra-small screens (mobile)
                        sm: 'flex', // Hide on small screens (tablet)
                    }
                }}>
                    {links.map((link) => (
                        <Link href={link.path} underline="none" key={link.path}>
                            <Typography variant="h5" color={window.location.pathname == link.path ? "lightgray" : "white"}>
                                {link.displayName}
                            </Typography>
                        </Link>
                    )
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export default Navbar
