import { Box } from '@mui/material'
import React from 'react'
import Data from './TeamData.json'
import TeamMember from './teamMember'
const Team = () => {
  return (
    <Box
      mx={"auto"}
      sx={{
        width: {
          xs: "100%",
          sm: "45%"
        }
      }}
    >
      {Data.members.map((member, index) => (
        <Box
          marginLeft={index % 2 === 0 ? "auto" : 0}
          marginRight={index % 2 === 1 ? "auto" : 0}
          maxWidth={"700px"}>
          <TeamMember data={member} index={index}></TeamMember>
        </Box>
      ))
      }
    </Box>
  )
}

export default Team
