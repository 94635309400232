import { Box, Typography } from '@mui/material'
import React from 'react'
import Data from "./emergencyContact.json"
import Contact from './contact'
import Divider from '@mui/material/Divider';

const Emergency = () => {
  return (
    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} width={"100%"}>
      <Box m={5}>
        <Box marginBottom={3} marginLeft={5} display="flex"  sx={
          {
            justifyContent: {
              xs:"center",
              sm:"left"
            }
          }
        }>
          <Typography variant="h4" sx={{
               fontSize: {
                xs: "1.7rem",
                sm: "2.1rem"
            },

          }}>Schnelle Hilfe für den Notfall</Typography>
        </Box>
        <Box display={"flex"} maxWidth={"1000px"} flexWrap={"wrap"} >
          {Data.map((emergencyContact) => (
            <Box marginLeft={5} sx={
              {
                marginLeft:{
                  xs: 0,
                  sm: 5
                }
              }
            }>
              <Divider />
              <Contact title={Object.keys(emergencyContact)[0]} number={emergencyContact[Object.keys(emergencyContact)].nummer} text={emergencyContact[Object.keys(emergencyContact)].text} />
            </Box>

          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default Emergency
